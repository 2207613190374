import { useState, useContext, FC, useMemo, useCallback } from 'react'
import {
  Typography,
  Card,
  CardContent,
  Grid,
  ExpandMoreIcon,
  Chip,
  Divider,
  ReplyIcon,
  styledComponent,
  Box,
  CheckboxCircle,
  SvgIcon,
  Rating,
  Link
} from '@hermes/web-components'
import { getRoundedReviewValue, tenantForChip } from '../../../utils/review'
import { AppDataContext } from '../../../providers/AppData'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import highlightText from '../../../utils/highlightText'
import { useDateTimeCorrector } from '../../../hooks/useDatetimeCorrector'
import { ProfileReview } from '../../../types/index'
import ReviewStatistic from './ReviewStatistic'
import { SearchReviewsContext } from '../../../providers/SearchReviews'
import { useRouter } from 'next/router'
import Avatar from '../../Avatar'

const REVIEW_TEXT_CHAR_LIMIT = 1600

const ReviewText = styledComponent(Typography)(({ theme }) => ({
  display: 'inline',
  '& .text-highlight': {
    color: theme.palette.primary.main
  }
}))

interface ReviewCardProps {
  review: ProfileReview
  selectedKeyword: string
  highlightedText?: string
  isPractice?: boolean
}

const ReviewCard: FC<ReviewCardProps> = ({ review, selectedKeyword, highlightedText, isPractice = false }) => {
  const reviewReply = review.comments?.[0]
  const { locale } = useRouter()

  const translate = useTranslateMessage()
  const { language } = useContext(AppDataContext)
  const {
    state: { currentLocation }
  } = useContext(SearchReviewsContext)
  const reviewDate = useDateTimeCorrector(review.createdAt)
  const replyDate = useDateTimeCorrector(reviewReply?.createdAt)

  const [expanded, setExpanded] = useState(true)
  const [showFullText, setShowFullText] = useState(false)

  const toggleExpandedContent = useCallback(() => setExpanded((prev) => !prev), [])
  const toggleShowFullText = useCallback(() => setShowFullText((prev) => !prev), [])

  const isTextTruncated = review.text.length > REVIEW_TEXT_CHAR_LIMIT

  const reviewText = useMemo(() => {
    if (showFullText) {
      return review.text
    }

    return `${review.text.slice(0, REVIEW_TEXT_CHAR_LIMIT)}${isTextTruncated ? '...' : ''}`
  }, [review.text, showFullText, isTextTruncated])

  return (
    <Card
      sx={{
        p: 2,
        mt: 2,
        boxShadow: 'none',
        borderRadius: 2
      }}
    >
      <Grid container alignItems="center">
        <Rating
          sx={{ '& svg': { width: 18, height: 17 } }}
          value={getRoundedReviewValue(review.averageRating)}
          readOnly
        />
        <Typography variant="body1" fontWeight="600" sx={{ mx: 1 }}>
          {getRoundedReviewValue(review.averageRating)}
        </Typography>
        <ExpandMoreIcon
          sx={{ cursor: 'pointer', width: '16px', ...(!expanded ? { transform: 'rotate(180deg)' } : {}) }}
          onClick={toggleExpandedContent}
        />
      </Grid>
      {!expanded && <ReviewStatistic review={review} />}
      <CardContent sx={{ px: 0, paddingBottom: '0 !important' }}>
        <ReviewText
          gutterBottom
          variant="body1"
          dangerouslySetInnerHTML={{ __html: highlightText(reviewText, highlightedText) }}
        />
        {isTextTruncated && (
          <Typography
            variant="body2"
            display="inline"
            sx={{ cursor: 'pointer', textDecoration: 'underline', margin: '0 4px' }}
            onClick={toggleShowFullText}
          >
            {translate(showFullText ? 'read_less' : 'read_more')}
          </Typography>
        )}
        <Grid container alignContent="center" sx={{ mt: 2 }}>
          <Typography variant="caption" color="text.disabled" sx={{ mr: 2 }}>
            {reviewDate}
          </Typography>
          <SvgIcon width="16" height="16">
            <use href="#user-verified-icon" />
          </SvgIcon>
          <Typography variant="caption" color="text.disabled" sx={{ ml: 0.25 }}>
            {translate('reviews.verified_patient')}
          </Typography>
        </Grid>
        {!!review?.rating?.reasonKeywords?.length && (
          <Grid container alignItems="center" sx={{ mt: 2 }}>
            <Typography variant="caption" sx={{ mr: 0.5 }}>
              {translate('reviews.patient_see_for')}
            </Typography>
            {review.rating.reasonKeywords.map((keyword, idx) => {
              const isSelected = keyword.name === selectedKeyword
              const isLink = Boolean(keyword?.id && keyword.slug?.length && currentLocation?.slug?.length)
              const localeTenant = tenantForChip(locale)
              return (
                <Chip
                  component={isLink ? Link : Box}
                  href={
                    isLink
                      ? `/${localeTenant}/find/${keyword.slug[0]}/${currentLocation?.slug}/${
                          isPractice ? 'practices' : 'specialists'
                        }`
                      : undefined
                  }
                  key={`chip-${keyword.name}-${idx}`}
                  label={
                    <Box display="flex" alignItems="center">
                      {isSelected && <CheckboxCircle sx={{ width: '18.33px' }} />}
                      <Typography
                        sx={{ paddingLeft: isSelected ? '8px' : 0 }}
                        variant="caption"
                        color="#1A3586"
                        fontWeight="500"
                      >
                        {keyword?.fullName?.[language] || keyword.name}
                      </Typography>
                    </Box>
                  }
                  sx={{
                    m: 0.25,
                    backgroundColor: isSelected ? 'rgba(0, 229, 208, 0.2)' : 'rgb(234, 238, 252)',
                    '.MuiChip-label': {
                      paddingLeft: isSelected ? 0 : '8px'
                    },
                    cursor: isLink ? 'pointer' : 'default'
                  }}
                />
              )
            })}
          </Grid>
        )}
      </CardContent>
      {!!reviewReply && locale !== 'au' && (
        <>
          <Divider sx={{ my: 2, borderColor: 'grey.100' }} />
          <Grid container alignItems="center" sx={{ mb: 1.5 }}>
            <ReplyIcon fontSize="inherit" sx={{ mr: 0.5 }} />
            <Typography variant="caption" color="text.disabled" fontWeight="600">
              {translate('reviews.reply_from', {
                name: isPractice
                  ? review.practice.name?.[language]
                  : `${review.specialist.firstName?.[language]} ${review.specialist.lastName?.[language]}`
              })}
            </Typography>
          </Grid>
          <Grid container alignItems="flex-start" wrap="nowrap">
            {!isPractice && (
              <Grid item>
                <Avatar
                  width={40}
                  height={40}
                  alt={review?.specialist?.images?.logo || ''}
                  src={review?.specialist?.images?.logo || ''}
                  lazy
                  sx={{ mr: 1 }}
                />
              </Grid>
            )}
            <Grid item>
              <Typography variant="body2">{reviewReply.text}</Typography>
              <Typography variant="caption" color="text.disabled" sx={{ mt: 0.5 }}>
                {replyDate}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  )
}

export default ReviewCard
